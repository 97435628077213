#Privacy {
  padding: 2rem;
}
#Privacy h1 {
  font-size: 3.2rem;
}

#Privacy h2 {
  font-size: 3.2rem;
}

#Privacy h3 {
  font-size: 2.8rem;
}

#Privacy h4 {
  font-size: 2.4rem;
}

#Privacy p {
  font-size: 1.4rem;
}

#Privacy li {
  font-size: 1.6rem;
}
